<template>
  <SearchForm ref="searchFormRef" :formModel="searchController.formModel" @search="search" :searchLoading="tableController.loading">
    <template #form>
      <el-form-item prop="doctorStaffCode">
        <FetchSelect v-model="searchController.formModel.doctorStaffCode" api="/web/system/doctorncp/getDoctorList" valueKey="doctorStaffCode" textKey="doctorName" />
      </el-form-item>
      <el-form-item prop="cardNo">
        <el-input placeholder="银行卡号" v-model="searchController.formModel.cardNo" clearable></el-input>
      </el-form-item>
      <el-form-item prop="ifDefault" :rules="[{ required: true }]">
        <el-select v-model="searchController.formModel.ifDefault" placeholder="请选择是否默认">
          <el-option label="1">是</el-option>
          <el-option label="0">否</el-option>
        </el-select>
      </el-form-item>
    </template>
    <template #controll>
      <el-button type="primary" @click="addModalRef?.open()">新增</el-button>
      <el-button type="primary" @click="exportStream">导出</el-button>
    </template>
  </SearchForm>

  <ProTable
    :columns="tableController.columns"
    :tableData="tableController.tableData"
    :loading="tableController.loading"
    :total="tableController.total"
    :pageState="tableController.pageState"
    @pageChange="tableController.onPageChange"
  />
  <AddModal ref="addModalRef" @successAdd="tableController.search" />
</template>

<script>
import { ref } from 'vue'
import SearchForm from '@/components/SearchForm'
import ProTable from '@/components/ProTable'
import FetchSelect from '@/components/FetchSelect'
import CustomLink from '@/components/customStyle/CustomLink'
import DrawerModal from '@/components/modal/DrawerModal'
import useExport from '@/hooks/useExport'
import AddModal from './AddModal.vue'
import { actionConfirm } from '@/utils/confirm'
import useSearchFormCtrller from '@/hooks/useSearchFormCtrller'
import useTableController from '@/hooks/useTableController'
import useRequestData from '@/hooks/useRequestData'
import { ElMessage } from 'element-plus'
import request from '@/utils/request'
import dayjs from 'dayjs'

export default {
  setup() {
    const drawerModalRef = ref(null)
    const searchFormRef = ref(null)
    const addModalRef = ref(null)
    // searchForm
    const searchController = useSearchFormCtrller({
      form: {
        doctorStaffCode: '',
        cardNo: '',
        ifDefault: '',
      },
    })

    //汇总导出
    const { setExportPostData, exportStream } = useExport('/web/system/bankcard/exportBankCardList')

    // table
    const columns = [
      { label: '医生姓名', prop: 'custName', width: 110 },
      { label: '手机号', prop: 'phoneNumber', width: 110 },
      {
        label: '银行卡',
        prop: 'bankName',
        width: 260,
        render: (_, e) => {
          return (
            <div>
              <img src={e.bankLogo} style={{ height: '12px', width: '12px', marginRight: '3px' }}></img>
              {e.bankName}-{e.cardNo}
            </div>
          )
        },
      },
      { label: '是否默认', prop: 'ifDefaultName', width: 150 },
      { label: '是否生效', prop: 'disabledName', width: 150 },
      { label: '创建时间', prop: 'createDateStr', width: 110 },
      {
        label: '操作',
        prop: 'actions',
        width: 120,
        fixed: 'right',
        render: (_, record) => {
          return (
            <div>
              <CustomLink
                onClick={() => {
                  drawerModalRef.value.open(record)
                }}
              >
                编辑
              </CustomLink>
              {record.ifDefault == '0' && (
                <CustomLink
                  onClick={() => {
                    setDefault(record)
                  }}
                >
                  设为默认
                </CustomLink>
              )}
              {record.ifDefault == '1' && (
                <CustomLink
                  onClick={() => {
                    setDefault(record)
                  }}
                >
                  取消默认
                </CustomLink>
              )}
              <CustomLink
                onClick={() => {
                  deleteBankCard(record)
                }}
              >
                删除
              </CustomLink>
            </div>
          )
        },
      },
    ]

    const tableController = useTableController({
      columns,
      ajax: ({ page, pageSize }) => {
        const { ...values } = searchController.formModel
        const postData = {
          page,
          rows: pageSize,
          ...values,
        }
        setExportPostData(postData)
        return request({
          url: '/web/system/bankcard/getBankCardList',
          data: postData,
        }).then((data) => ({ list: data.data ?? [], totalNum: data.rowTop ?? 0 }))
      },
    })

    function search() {
      tableController.search()
    }
    function refresh() {
      tableController.refresh()
    }

    const setDefault = async ({ personCode, cardNo, ifDefault }) => {
      let str = ifDefault == '0' ? '设为' : '取消'
      actionConfirm(`确认要将该银行卡${str}默认？`, async () => {
        const postData = {
          personCode,
          cardNo,
          ifDefault: ifDefault == '0' ? '1' : '0',
        }
        await request({
          url: '/web/system/bankcard/setDefaultBankCard',
          data: postData,
        }).then(() => {
          tableController.refresh()
          ElMessage.success('操作成功')
        })
      })
    }

    const deleteBankCard = async ({ personCode, cardNo }) => {
      actionConfirm(`确认要删除该银行卡？`, async () => {
        const postData = {
          personCode,
          cardNo,
        }
        await request({
          url: '/web/system/bankcard/removeBankCard',
          data: postData,
        }).then(() => {
          tableController.refresh()
          ElMessage.success('操作成功')
        })
      })
    }

    return {
      addModalRef,
      searchFormRef,
      drawerModalRef,
      searchController,
      tableController,
      search,
      refresh,
      exportStream,
    }
  },
  components: {
    SearchForm,
    ProTable,
    FetchSelect,
    CustomLink,
    DrawerModal,
    AddModal,
  },
}
</script>

<style lang="less"></style>
<style lang="less" scoped>
@import url('~@/common/styles.less');
.statistics_wrap {
  .flexCenter;
  background-color: #fff;
  margin: 0 0 15px;
  padding: 15px 7.5px;
  .statistics_item {
    .flexColumn;
    flex: 1 0 0;
    border: 1px solid #f0f0f0;
    border-radius: 6px;
    margin: 0 7.5px;
    padding: 7px 0;
    .statistics_count {
      font-size: 17px;
      font-weight: bolder;
      margin-top: 3px;
      color: red;
    }
  }
}
</style>
